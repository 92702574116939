.content-head
{
	position: relative;
	margin-bottom: 5px;
	height: 40px;
}
.content-head .head-title
{
	position: absolute;
	bottom: 0;
	width: -moz-calc(100% - 340px); /* Firefox */
	width: -webkit-calc(100% - 340px); /* WebKit */
	width: calc(100% - 340px); /* Standard */
}
.content-head .title
{
	font-weight: 700;
	font-size: 16px;
	color: #a0aab3;
}
.content-head .head-select
{
	position: absolute;
	top: 0;
	right: 0;
}
.content-head .genres-list
{
	border-radius: 3px;
	padding-left: 15px;
	padding-right: 15px;
	color: #a0aab3;
	font-weight: 600;
	font-size: 14px;
	background: none;
	border: 2px solid #252525;
	line-height: 36px;
}
.select-option
{
	display: none;
}
/*filter btn start*/
.filterContainer
{
	float: right;
}
.filter__title
{
	float: left;
	height: 42px;
	padding-right: 15px;
}
.filter__title-text
{
	line-height: 42px;
	color: #a0aab3;
	font-weight: 800;
}
.filter
{
	float: right;
	cursor: pointer;
	font-size: 14px;
	text-transform: uppercase;
	min-width: 220px;
	max-width: 300px;
	position: relative;
	color: #a0aab3;
}
.filter .iScrollVerticalScrollbar
{
	right: 0;
}
@media (max-width: 483px)
{
	.head-title
	{
		display: none;
	}
	.filterContainer
	{
		width: 100%;
	}
	.filter
	{
		max-width: -moz-calc(100% - 60px); /* Firefox */
		max-width: -webkit-calc(100% - 60px); /* WebKit */
		max-width: calc(100% - 60px);
	}
	.filter__title
	{
		padding-right: 5px;
	}
}
.filter__btn
{
	color: #a0aab3;
	border: 1px solid #252525;
	position: relative;
	display: block;
	background-color: #0c0c0c;
	font-weight: 600;
	line-height: 40px;
	padding: 0 15px;

}
.filter__btn-text
{
	display: block;
	text-align: left;
	margin-right: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.filter__icon-down
{
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #a0aab3;
	display: inline-block;
	vertical-align: middle;
	margin-left: 4px;
	margin-top: -1px;
	transition: all 0.2s;
}
.is-open .filter__icon-down
{
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
}
.scroll-wrapper
{
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 300px;
	overflow: hidden;
	opacity: 0;
	z-index: -1;
	border: 1px solid #252525;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	font-size: 0;
	line-height: 0;
	background: #0c0c0c;
}
.filter__list
{
	font-size: 14px;
	line-height: 36px;
	width: inherit;
	box-sizing: border-box;
	font-weight: 600;
	background-color: #0c0c0c;
	padding: 10px 0 10px 0;
}
.filter__item
{
	padding: 0 15px;
}
.filter__item:hover
{
	opacity: 0.8;
}
.filter.is-open .scroll-wrapper
{
	z-index: 99;
	opacity: 1;
	top: 41px;
	transition: all 0.2s;
}
.filter__item-link
{
	color: #a0aab3;
	line-height: 20px;
	display: inline-block;
}
.is-selected .filter__item-link
{
	color: #00c473;
}
.categories-result .no-content
{
	padding: 80px 0;
	text-align: center;
	font-size: 24px;
	color: #fff;
	font-weight: 600;
}
.categories-result .program
{
	display: inline-block;
	float: none;
}
/*filter btn end*/
